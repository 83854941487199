<template>
  <Layout>
    <Header>
      <CText color="white" :font-size="['md', 'lg', '2xl']">{{
        label("header_title")
      }}</CText>
    </Header>

    <CBox v-if="docs && docs.length" :p="4" max-width="1440px">
      <CFlex justify="space-between">
        <div>
          <c-breadcrumb>
            <c-breadcrumb-item>
              <c-breadcrumb-link href="https://www.wildspecies.ca/">{{
                label("home")
              }}</c-breadcrumb-link>
            </c-breadcrumb-item>

            <c-breadcrumb-item>
              <c-breadcrumb-link as="router-link" :to="`/${language}`">{{
                label("search")
              }}</c-breadcrumb-link>
            </c-breadcrumb-item>

            <c-breadcrumb-item isCurrentPage>
              <c-breadcrumb-link href="#">{{
                speciesCurrent[`common_name_${language}`]
              }}</c-breadcrumb-link>
            </c-breadcrumb-item>
          </c-breadcrumb>
        </div>
        <rank-modal-container opentext="Rank Definitions">
          <ENDefinitionOfRanks />
        </rank-modal-container>
      </CFlex>
      <CFlex wrap="wrap">
        <!-- Species Current -->
        <CBox flex="1" height="auto" :my="5" :mr="[0, 0, 5]">
          <Card>
            <CBox v-if="speciesCurrent.year >= 2015 && !isDeleted">
              <CText v-if="sc_doc" fontSize="xl" color="green.500">
                {{ sc_doc[`common_name_${language}`] }}
              </CText>
              <CText fontSize="sm" as="i">
                {{ speciesCurrent[`scientific_name`] }}
              </CText>
              <CText color="gray.500" font-size="sm">{{
                label("species_code") + ": " + speciesCurrent.code
              }}</CText>
              <CDivider border-color="gray.300" />
              <CText fontSize="l" :pt="2">
                {{ `${label("general_status")} ${currentYear}: ` }}
                <CText as="strong">{{ rankText }}</CText>
              </CText>
              <CText v-if="speciesCurrent.region_count">
                {{ `${label("present_in")} ${regionCount}` }}
                {{
                  speciesCurrent.region_count == 1
                    ? label("region_singular")
                    : label("regions")
                }}
              </CText>
              <CText>
                {{ label("this_species_is")
                }}<strong>{{
                  origin[speciesCurrent.ca_origin_code][`label_${language}`]
                }}</strong>
                {{ label("in_canada") }}
                <CText as="span" v-if="speciesCurrent.ca_origin_code === 'NAT'">
                  <CText as="span" v-if="exoticRegionList.length > 0">{{
                    label("but_exotic")
                  }}</CText>
                  <CText
                    as="span"
                    v-for="(item, index) in exoticRegionList"
                    :key="index"
                  >
                    <CText as="span" v-if="index > 0">, </CText>
                    {{ `${item}` }}
                  </CText>
                </CText>
              </CText>
              <CText>
                {{ label("migratory_sp") }}
                <CText as="span">{{
                  boolean_codes[speciesCurrent.ca_migratory][language]
                }}</CText>
              </CText>
              <CText>
                {{ label("taxonomic_group") + ": " }}
                <CText as="strong">{{
                  speciesCurrent[`taxonomic_group_${language}`]
                }}</CText>
              </CText>
              <CText mb="4">
                <CLink
                  color="green.500"
                  :href="speciesCurrent[`uri_${language}`]"
                  is-external
                >
                  {{ label("learn_species") }}
                  {{ speciesCurrent[`taxonomic_group_${language}`] }}
                  {{ label("in_canada") }}.</CLink
                >
              </CText>
              <CText my="4" fontSize="sm" as="p">
                <sup>*</sup>{{ label("status_disclaimer") }}
              </CText>
            </CBox>
            <CBox v-else>
              <CText
                >{{ label("species_code") }}: {{ $route.query.code }}</CText
              >
              <CText v-if="sc_doc" fontSize="xl" color="green.500">
                {{ sc_doc[`common_name_${language}`] }}
              </CText>
              <CText fontSize="sm" as="i">
                {{ speciesCurrent[`scientific_name`] }}
              </CText>
              <CDivider border-color="gray.300" />
              <CText fontSize="l" :pt="2">
                {{
                  `${label("general_status")} ${
                    speciesCurrent.year
                  }: ${rankTextYear(
                    speciesCurrent.year,
                    speciesCurrent.ca_map_rank
                  )}`
                }}
              </CText>
            </CBox>
            <CText mt="4" v-if="speciesCurrent.year >= 2015 && !isDeleted">
              <CLink
                color="green.500"
                :href="ns_url(speciesCurrent.taxon_id)"
                is-external
                >{{ label("access_nse") }}</CLink
              >
            </CText>
          </Card>
        </CBox>
        <CBox
          :width="['100%', '100%', '55%']"
          :mt="5"
          border="1px solid #E2E8F0"
        >
          <!-- MAP -->
          <CBox>
            <CTabs
              variant="enclosed-colored"
              is-fitted
              variant-color="green"
              size="sm"
              is-manual
              @change="onTabClick"
              border-bottom="1px solid #E2E8F0"
              :default-index="years.indexOf(Number($route.query.year))"
            >
              <CTabList>
                <CTab v-for="(year, i) in years" :key="i">{{ year }}</CTab>
              </CTabList>
            </CTabs>
          </CBox>
          <Map :species="speciesYear" />
        </CBox>
      </CFlex>

      <CBox width="100%">
        <CBox wrap="wrap" w="100%" :mt="4">
          <CBox :px="3" :pb="5">
            <CBox :mb="3">
              <TabTitle :title="label('common_names')" />
            </CBox>
            <CText as="Strong">{{ label("en_common_name") }}</CText>
            <CText as="span" v-if="sc_doc">{{ sc_doc.common_name_en }} </CText>
            <CText>{{ speciesCurrent[`en_justification_${language}`] }}</CText>
            <CText as="strong">{{ label("fr_common_name") }}</CText>
            <CText as="span" v-if="sc_doc">{{ sc_doc.common_name_fr }} </CText>
            <CText>{{ speciesCurrent[`fr_justification_${language}`] }}</CText>
          </CBox>

          <DataTable
            :headings="[
              label('year'),
              label('en_common_name'),
              label('fr_common_name'),
            ]"
            :fields="['year', 'common_name_en', `common_name_fr`]"
            :rows="docs"
            hd_bg="none"
          />
        </CBox>

        <CBox :mt="5">
          <DataTable
            :title="label('scientific_names')"
            :headings="[
              label('year'),
              label('scientific_name'),
              label('family'),
              label('order'),
            ]"
            :fields="['year', 'scientific_name', `family`, `order`]"
            :rows="docs"
            hd_bg="none"
          >
            <!-- Higher Order -->
            <CBox :py="4">
              <CText as="span"
                ><strong>{{ label("higher_classification") }}: </strong></CText
              >
              <CText as="span"
                ><CText as="span" color="green.600">{{
                  docs[0].superkingdom
                }}</CText>
                (Superkingdom)</CText
              >
              <CText as="span">
                >
                <CText as="span" color="green.600">{{ docs[0].kingdom }}</CText>
                (Kingdom)</CText
              >
              <CText as="span">
                >
                <CText as="span" color="green.600">{{
                  docs[0].subkingdom
                }}</CText>
                (Subkingdom)</CText
              >
              <CText as="span">
                >
                <CText as="span" color="green.600">{{
                  docs[0].infrakingdom
                }}</CText>
                (Infrakingdom)</CText
              >
              <CText as="span">
                >
                <CText as="span" color="green.600">{{
                  docs[0].superphylum
                }}</CText>
                (Superphylum)</CText
              >
              <CText as="span">
                >
                <CText as="span" color="green.600">{{ docs[0].phylum }}</CText>
                (Pyhlum)</CText
              >
              <CText as="span" v-if="docs[0].subpyhlum">
                >
                <CText as="span" color="green.600">{{
                  docs[0].subpyhlum
                }}</CText>
                (Subpyhlum)</CText
              >
              <CText as="span" v-if="docs[0].infrapyhlum">
                >
                <CText as="span" color="green.600">{{
                  docs[0].infrapyhlum
                }}</CText>
                (Infrapyhlum)</CText
              >
              <CText as="span" v-if="docs[0].superclass">
                >
                <CText as="span" color="green.600">{{
                  docs[0].superclass
                }}</CText>
                (Superclass)</CText
              >
              <CText as="span" v-if="docs[0].class_">
                >
                <CText as="span" color="green.600">{{ docs[0].class_ }}</CText>
                (Class)</CText
              >
              <CText as="span" v-if="docs[0].subclass">
                >
                <CText as="span" color="green.600">{{
                  docs[0].subclass
                }}</CText>
                (Subclass)</CText
              >
              <CText as="span" v-if="docs[0].infraclass">
                >
                <CText as="span" color="green.600">{{
                  docs[0].infraclass
                }}</CText>
                (Infraclass)</CText
              >
              <CText as="span" v-if="docs[0].superorder">
                >
                <CText as="span" color="green.600">{{
                  docs[0].superorder
                }}</CText>
                (Superorder)</CText
              >
            </CBox>
          </DataTable>
        </CBox>

        <CBox :mt="5" :pl="3">
          <TabTitle :title="label('priority_score')" />
          <CText v-if="speciesCurrent.perc_range_in_canada" :pt="3"
            >{{ label("perc_canada") }}
            {{ speciesCurrent.perc_range_in_canada }}</CText
          >
          <CText v-if="speciesCurrent.priority" :pt="2"
            >{{ label("priority_score_explain") }}
            {{ speciesCurrent.priority }}</CText
          >
          <CText v-else :pt="2">{{ label("not_at_risk") }}</CText>
          <CText v-if="speciesCurrent[`why_${language}`]" :pt="2"
            >{{ label("why_at_risk") }}
            {{ speciesCurrent[`why_${language}`] }}</CText
          >
          <CText v-if="speciesCurrent.assessed_by_cosewic === 'true'" :pt="2"
            >{{ label("assessed") }} {{ boolean_codes["Yes"][language] }}</CText
          >
          <CText v-else-if="speciesCurrent.priority" :pt="2"
            >{{ label("assessed") }} {{ boolean_codes["No"][language] }}</CText
          >
          <CText
            v-if="docs[0][`cosewic_link_${language}`]"
            :pt="2"
            color="green.500"
          >
            <a :href="docs[0][`cosewic_link_${language}`]" target="_blank">{{
              label("cosewic_link")
            }}</a>
          </CText>
        </CBox>

        <CFlex wrap="wrap" :mt="5">
          <CBox width="100%" :pt="3">
            <!-- Rank Text Year is supplied 2015 as a 'current' year as this field, in this place, uses the rounded national rank natureserve value, for which there is no historiacal value - even for 2010 -->
            <!-- TODO remove hard coded '2015' year -->
            <DataTable
              :headings="[
                label('year'),
                label('rounded_national_rank'),
                label('desc_change'),
                label('reason_change'),
                '',
              ]"
              :fields="[
                'year',
                'rounded_national_rank',
                `description_${language}`,
                'reason',
                `reason_extra_${language}`,
              ]"
              :rows="
                docs.map((row) => ({
                  ...row,
                  rounded_national_rank: rankTextYear(
                    2020,
                    row.rounded_national_rank
                  ),
                  reason: row[`reason_${language}`],
                }))
              "
              hd_bg="none"
              :title="label('trend_rank')"
            />
          </CBox>

          <CBox width="100%" :pt="5" :pl="3">
            <TabTitle :title="label('orig_ranks')" />
            <CFlex :wrap="['wrap']" mt="2">
              <!-- Loop original rank fields -->
              <CBox
                v-for="(rf, i) in original_rank_fields"
                :key="`${i}_orf`"
                :pr="5"
                :pb="4"
                font-size="sm"
                :width="['100%', '50%', '350px']"
              >
                <CText :px="3" as="strong">{{ regionLabel(rf) }}</CText>
                <CList :font-size="['xs', 'sm']">
                  <!-- Loop Years in fields -->
                  <CListItem
                    v-for="(year, index) in docs"
                    :key="`${index}_orr`"
                  >
                    <div v-if="showOriginalRankField(year.year, rf)">
                      <!-- If a value is returned, display it -->
                      <CText v-if="rankTextYear(year.year, year[rf])">
                        <CDivider :m="1" border-color="gray.300" />
                        <CText :px="3">{{
                          `${year.year}: ${rankTextYear(
                            year.year,
                            year[rf]
                          )} ${rankCodeLabel(year[rf.replace("_map", "")])}`
                        }}</CText>
                      </CText>
                      <!-- Else default not-assessed on -->
                      <CText v-else>
                        <CDivider :m="1" border-color="gray.300" />
                        <CText :px="3" color="">{{
                          `${year.year}: ${label("not_present")}`
                        }}</CText>
                      </CText>
                    </div>
                  </CListItem>
                </CList>
              </CBox>
            </CFlex>
          </CBox>
        </CFlex>
      </CBox>
    </CBox>
  </Layout>
</template>

<script>
import { CBox, CText, CList, CListItem } from "@chakra-ui/vue";

import { mapState } from "vuex";
import {
  rank_codes,
  labels,
  origin,
  regions,
  original_rank_fields,
  boolean_codes,
} from "../config";
import { years } from "../config";
import { getSpeciesRecords, getSpeciesCurrent } from "../api";
import DataTable from "../components/DataTable.vue";
import Card from "../components/Card.vue";

import Header from "../components/Header.vue";

import Map from "../components/Map.vue";
import TabTitle from "../components/TabTitle.vue";
import RankModalContainer from "../components/RankModalContainer.vue";

export default {
  components: {
    CBox,
    CText,
    CList,
    CListItem,
    Header,
    Map,
    DataTable,
    TabTitle,
    Card,
    RankModalContainer,
  },
  props: {
    name: String,
    clickedYear: Number,
    query_language: {
      default: "",
      type: String,
    },
  },
  metaInfo() {
    return {
      title: this?.sc_doc?.scientific_name,
      titleTemplate: "%s | Wildspecies.ca",
      meta: [
        {
          name: this.sc_doc?.scientific_name,
          content: this.sc_doc?.scientific_name,
        },
      ],
    };
  },
  data() {
    return {
      boolean_codes,
      labels,
      origin,
      docs: [],
      tabIndex: 0,
      selectedYear: 2020,
      sc_doc: null,
    };
  },
  activated() {
    this.sc_doc = null;
    this.docs = null;
    this.selectedYear = this.$route.query.year
      ? this.$route.query.year
      : "2020";
    this.code = this.$route.query.code;
    this.getSpecies(this.code);
  },
  mounted() {
    this.sc_doc = null;
    this.docs = null;
    this.code = this.$route.query?.code;
    if (!this.code) this.$router.push("/");
    this.getSpecies(this.code);
    this.setLanguage();
  },
  watch: {
    $route: function() {
      this.docs = [];
      this.getSpecies(this.$route.query.code);
    },
  },
  methods: {
    getSpecies(code) {
      getSpeciesRecords(code).then((response) => {
        this.docs = response.data;
      });
      getSpeciesCurrent(code).then((response) => {
        this.sc_doc = response.data;
      });
    },
    label(label) {
      return labels[label][this.language];
    },
    ns_url(global_id) {
      return `https://explorer.natureserve.org/Taxon/ELEMENT_GLOBAL.${global_id}`;
    },

    onTabClick(event) {
      this.tabIndex = event;
      this.selectedYear = this.years[event];
    },

    regionLabel(raw) {
      const value = raw.split("_")[0];
      const region = regions.find((rg) => rg.prefix === value);
      return region ? region[`label_${this.language}`] : "";
    },

    rankTextYear(year, code) {
      const numYear = parseInt(year);
      const rankTxt = rank_codes[this.isHistorical(numYear)][code];
      return rankTxt ? rankTxt[`label_${this.language}`] : "";
    },

    rankCodeLabel(label) {
      return label ? `(${label})` : "";
    },

    rankCode(code, year) {
      return code
        ? rank_codes[this.isHistorical(year)][code][`label_${this.language}`]
        : "";
    },

    isHistorical(year) {
      return years.find((item) => item.label == year).status;
    },

    rowColor(index) {
      return index % 2 ? "#ffffff" : "#c2ebd4";
    },

    showOriginalRankField(year, field) {
      // If year is less than 2010 and field is an NF or LB don't show it
      if (year <= 2010 && ["nf_map_rank", "lb_map_rank"].includes(field))
        return false;
      // If year is 2010 or gtr, don't show combined NL field.
      if (year > 2010 && field === "nl_map_rank") return false;
      return true;
    },

    setLanguage() {
      const lg = navigator.language;
      const browser_language_code = lg.substring(0, 2) === "fr" ? "fr" : "en";
      const language_code =
        this.query_language === "en" || this.query_language === "fr"
          ? this.query_language
          : browser_language_code;
      if (!this.query_language) {
        window.location.href = `/#/${language_code}`;
      }
      this.$store.dispatch("setLanguage", language_code);
    },
  },

  computed: {
    ...mapState({
      species: "selectedSpecies",
      language: "language",
      currentYear: "current_year",
    }),

    speciesYear() {
      return this.docs.find((species) => species.year == this.selectedYear);
    },

    isDeleted() {
      return this.docs[0]?.description_code === "DEL";
    },

    speciesCurrent() {
      return this.docs?.length > 0 && !this.isDeleted
        ? this.docs[0]
        : this.docs[1];
    },

    rankText() {
      return rank_codes.c[this.speciesCurrent.ca_map_rank][
        `label_${this.language}`
      ];
    },

    years() {
      return this.docs.length > 0
        ? this.docs.map((doc) => doc.year)
        : years.map((item) => item.label);
    },

    original_rank_fields() {
      return original_rank_fields;
    },

    province_origin_fields() {
      const field_names = Object.keys(this.docs[0]);
      const fields = field_names.filter(
        (field) => field.includes("_origin") && !field.includes("ca_")
      );
      return fields;
    },

    exoticRegionList() {
      return this.province_origin_fields.reduce((accum, item) => {
        const origin = this.speciesCurrent[item];
        if (origin === "EXO") {
          const item_prefix = item.split("_")[0];
          const region = regions.find((rgn) => rgn.prefix === item_prefix);
          accum.push(region[`label_${this.language}`]);
        }
        return accum;
      }, []);
    },

    regionCount() {
      return this.province_origin_fields.reduce((accum, item) => {
        const origin = this.speciesCurrent[item];
        if (origin === "EXO" || origin === "NAT") {
          accum += 1;
        }
        return accum;
      }, 0);
    },
  },
};
</script>

<style></style>
