import { render, staticRenderFns } from "./SpeciesProfile.vue?vue&type=template&id=6f69cec4&"
import script from "./SpeciesProfile.vue?vue&type=script&lang=js&"
export * from "./SpeciesProfile.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* chakra-loader */
const installChakraComponents = require("!../../node_modules/chakra-loader/lib/install-components.js")
installChakraComponents(component, {CText: require('@chakra-ui/vue').CText, CBreadcrumbLink: require('@chakra-ui/vue').CBreadcrumbLink, CBreadcrumbItem: require('@chakra-ui/vue').CBreadcrumbItem, CBreadcrumb: require('@chakra-ui/vue').CBreadcrumb, CFlex: require('@chakra-ui/vue').CFlex, CDivider: require('@chakra-ui/vue').CDivider, CLink: require('@chakra-ui/vue').CLink, CBox: require('@chakra-ui/vue').CBox, CTab: require('@chakra-ui/vue').CTab, CTabList: require('@chakra-ui/vue').CTabList, CTabs: require('@chakra-ui/vue').CTabs, CListItem: require('@chakra-ui/vue').CListItem, CList: require('@chakra-ui/vue').CList})
